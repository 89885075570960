





























import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTeamCard from '@improve/common-components/src/components/widgets/BaseTeamCard.vue';
import BaseAddButton from '@improve/common-components/src/components/widgets/BaseAddButton.vue';
import Team from '@improve/common-utils/src/model/Team';
import Organization from '@improve/common-utils/src/model/Organization';
import StatsData from '@improve/common-utils/src/model/StatsData';
import BaseTicketCard from '@/components/ticket/BaseTicketCard.vue';

@Component({
  name: 'TeamsTab',
  components: {
    BaseTeamCard,
    BaseAddButton,
    BaseTicketCard
  }
})
export default class TeamsTab extends Vue {
  @Getter currentOrganization?: Organization;

  @Getter currentUserId?: string;

  @Getter teamStatsByID!: Map<string, StatsData>;

  @Action fetchUserTeams!: ActionMethod;

  @Action fetchTeamStats!: ActionMethod;

  teams = new Array<Team>();

  async created(): Promise<void> {
    this.teams = await this.fetchUserTeams(this.currentUserId);
    const teamIds = this.teams.map((t) => t.id);
    await this.fetchTeamStats(teamIds);
    this.$forceUpdate();
  }

  fetchTeamStat(team: Team): StatsData {
    if (!this.teamStatsByID || !this.teamStatsByID.has(team.id!)) {
      return new StatsData();
    }
    return this.teamStatsByID.get(team.id!)!;
  }

  goToTeam(teamId: string): void {
    this.$router.push({
      name: 'TeamDetails',
      params: { id: teamId }
    });
  }

  gotoCreateVirtualTeam(): void {
    this.$router.push({ name: 'CreateVirtualTeam' });
  }
}
